// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  user: {}
};

// ==============================|| SLICE - MENU ||============================== //

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload.user;
    }
  }
});

export default user.reducer;

export const { setUser } = user.actions;
