import PropTypes from 'prop-types';
import { useRef, useState, useContext } from 'react';
import { AuthContext } from 'context/AuthContext';

import { useTheme } from '@mui/material/styles';
import { Avatar, IconButton, Stack, Typography } from '@mui/material';

// project import
// assets
import { LogoutOutlined } from '@ant-design/icons';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const { currentUser, signOut } = useContext(AuthContext);

  const handleLogout = async () => {
    signOut();
  };
  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
      <Avatar alt="profile user" src={currentUser?.data?.photoURL} sx={{ width: 32, height: 32 }} />
      <Typography noWrap variant="subtitle1">
        {currentUser?.data?.displayName}
      </Typography>
      <IconButton size="large" color="secondary" onClick={handleLogout}>
        <LogoutOutlined />
      </IconButton>
    </Stack>
  );
};

export default Profile;
