// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  filter: {}
};

// ==============================|| SLICE - MENU ||============================== //

const filter = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter(state, action) {
      state.filter = action.payload;
    }
  }
});

export default filter.reducer;

export const { setFilter } = filter.actions;
