import { Snackbar, Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setErrorType } from 'store/reducers/error';
import React from 'react';

const Alerts = () => {
  const { errorType } = useSelector((state) => state.error);
  // const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setErrorType({ errorType: {} }));

    // setOpen(false);
  };
  return (
    <Snackbar open={!!errorType?.message} autoHideDuration={5000} onClose={handleClose}>
      <Alert severity={errorType?.severity ?? 'error'} sx={{ width: '100%' }} elevation={6} variant="filled">
        {errorType?.message}
      </Alert>
    </Snackbar>
  );
};

export default Alerts;
