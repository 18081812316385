import { domain } from 'config';
import http from 'utils/axiosService';
import { useState, useEffect } from 'react';

const customersEndpoint = `${domain}/api/Customers`;

export const useCustomerHook = () => {
  const [customers, setCustomers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await http.get(customersEndpoint);
      setCustomers(response.data);
    };
    fetchData();
  }, []);
  return customers;
};
