import { useNavigate } from 'react-router-dom';
import { SignOutUser, auth, userStateListener } from 'firebaseUtil';
import { createContext, useState, useEffect } from 'react';
import http from 'utils/axiosService';
import { domain } from 'config';
import { setErrorType } from 'store/reducers/error';
import { useDispatch } from 'react-redux';

import { setUser } from 'store/reducers/user';
import { getRedirectResult } from 'firebase/auth';
const endpoint = `${domain}/api/user`;
export const AuthContext = createContext({
  currentUser: {},
  setCurrentUser: (_user) => {},
  signOut: () => {}
});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState({ isLoading: true, data: {} });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        setCurrentUser({ isLoading: false, data: result?.user });
      })
      .catch((error) => {
        console.error('getRedirectResult Error', error);
      });

    const unsubscribe = userStateListener(async (user) => {
      console.log('userStateListener', user);
      if (user && !currentUser?.data?.isAuthenticated) {
        const isUserAuthorized = await checkUser(user);
        if (isUserAuthorized) {
          setCurrentUser({ isLoading: false, data: user });
          dispatch(setErrorType({ errorType: { message: '', severity: 'error' } }));
        } else {
          dispatch(setErrorType({ errorType: { message: 'Account Not Authorized For Access', severity: 'error' } }));
          signOut();
        }

        const role = await http.get(`${domain}/api/public/GetUserRole`, { params: { email: user.email } });
        if (role.data) {
          dispatch(setUser({ user: { role: role.data.split(',') } }));
        }
      }
    });
    return unsubscribe;
  }, []);

  const checkUser = async (user) => {
    return await http
      .get(endpoint)
      .then((response) => {
        return true;
      })
      .catch((response) => {
        return false;
      });
  };
  // As soon as setting the current user to null,
  // the user will be redirected to the home page.
  const signOut = () => {
    SignOutUser();
    setCurrentUser({ isLoading: false, data: null });
    navigate('/');
  };

  const value = {
    currentUser,
    setCurrentUser,
    signOut
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
