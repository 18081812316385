// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  errorType: { message: null, severity: null }
};

// ==============================|| SLICE - MENU ||============================== //

const errorType = createSlice({
  name: 'errorType',
  initialState,
  reducers: {
    setErrorType(state, action) {
      state.errorType = action.payload.errorType;
    }
  }
});

export default errorType.reducer;

export const { setErrorType } = errorType.actions;
