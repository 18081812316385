// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AuthProvider } from './context/AuthContext';
import './custom.css';
import axios from 'axios';
import { domain } from 'config';
import React from 'react';
import { getToken } from 'firebaseUtil';
import Alerts from 'components/Alerts';
// Add a request interceptor
axios.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    const accessToken = await getToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    config.baseURL = domain;

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

const App = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <ThemeCustomization>
      <ScrollTop>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ScrollTop>
      <Alerts />
    </ThemeCustomization>
  </LocalizationProvider>
);

export default App;
