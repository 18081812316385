// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import error from './error';
import user from './user';
import filter from './filter';
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, error, user, filter });

export default reducers;
